/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import Content, { HTMLContent } from 'src/components/Content'
import { PageHeading } from 'src/components/molecules/Heading'
import { Inner } from 'src/components/layouts/Inner'
import styled, { mediaQuery } from 'src/styles'
import { useSiteMetadata } from 'src/hooks'
import PageMeta from 'src/components/PageMeta'

type Props = any

export const PrivacyPageTemplate: React.FC<Props> = ({ title, titleEn, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <ScHeading component="h2" heading={title} subHeading={titleEn} />
      <PageContent className="content content-privacy" content={content} />
    </>
  )
}

// CSS in JS
const ScHeading = styled(PageHeading)`
  margin-bottom: 2.5rem;
  ${mediaQuery('sm')} {
    margin-bottom: 5rem;
  }
`
// End CSS in JS

type PageProps = any

const PrivacyPage: React.FC<PageProps> = ({ data }) => {
  const { siteTitle } = useSiteMetadata()
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PageMeta title={`${post.frontmatter.title} | ${siteTitle}`} description={post.frontmatter.descriptionSeo} />
      <Inner>
        <PrivacyPageTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          titleEn={post.frontmatter.titleEn}
          content={post.html}
        />
      </Inner>
    </Layout>
  )
}

export default PrivacyPage

export const privacyPolicyPageQuery = graphql`
  query PrivacyPolicyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        titleEn
        descriptionSeo
      }
    }
  }
`
